import React from "react";
import "./Section1.scss";
import avalanche from "../../../common/asset/images/co-op/avalanche.png";
import Block_Odyssey from "../../../common/asset/images/co-op/Block_Odyssey.png";
import bondez from "../../../common/asset/images/co-op/bondez.png";
import DB_Financial_Investment from "../../../common/asset/images/co-op/DB_Financial_Investment.png";
import ethereum from "../../../common/asset/images/co-op/ethereum.png";
import EY from "../../../common/asset/images/co-op/EY.png";
import finger from "../../../common/asset/images/co-op/finger.png";
import igis_asset_management from "../../../common/asset/images/co-op/igis_asset_management.png";
import jfwf_festival from "../../../common/asset/images/co-op/jfwf_festival.png";
import LG_CNS from "../../../common/asset/images/co-op/LG_CNS.png";
import LifeSite from "../../../common/asset/images/co-op/LifeSite.png";
import mirae_asset from "../../../common/asset/images/co-op/mirae_asset.png";
import n_computing from "../../../common/asset/images/co-op/n_computing.png";
import polygon from "../../../common/asset/images/co-op/polygon.png";
import rapbeat from "../../../common/asset/images/co-op/rapbeat.png";
import ripple from "../../../common/asset/images/co-op/ripple.png";
import SamSung_SDS from "../../../common/asset/images/co-op/SamSung_SDS.png";
import Shinhan_securities from "../../../common/asset/images/co-op/Shinhan_securities.png";
import SK_C_C from "../../../common/asset/images/co-op/SK_C&C.png";
import UMF from "../../../common/asset/images/co-op/UMF.png";
import xangle from "../../../common/asset/images/co-op/xangle.png";
import {useNavigate} from "react-router-dom";

const COOP_IMGS_1 = [
	{id: "avalanche", src: avalanche, alt: "avalanche"},
	{id: "finger", src: finger, alt: "finger"},
	{id: "xangle", src: xangle, alt: "xangle"},
	{id: "Shinhan_securities", src: Shinhan_securities, alt: "Shinhan securities"},
	{id: "mirae_asset", src: mirae_asset, alt: "mirae asset"},
	{id: "LG_CNS", src: LG_CNS, alt: "LG CNS"},
	{id: "igis_asset_management", src: igis_asset_management, alt: "igis asset management"},
	{id: "DB_Financial_Investment", src: DB_Financial_Investment, alt: "DB Financial Investment"},
	{id: "ethereum", src: ethereum, alt: "ethereum"},
]

const COOP_IMGS_2 = [
	{id: "Block_Odyssey", src: Block_Odyssey, alt: "Block Odyssey"},
	{id: "jfwf_festival", src: jfwf_festival, alt: "jfwf festival"},
	{id: "LifeSite", src: LifeSite, alt: "LifeSite"},
	{id: "n_computing", src: n_computing, alt: "n computing"},
	{id: "polygon", src: polygon, alt: "polygon"},
	{id: "rapbeat", src: rapbeat, alt: "rapbeat"},
	{id: "SK_C_C", src: SK_C_C, alt: "SK C&C"},
	{id: "UMF", src: UMF, alt: "UMF"},
	{id: "bondez", src: bondez, alt: "bondez"},
]

function Section1() {
	const navigate = useNavigate();
	const openEqhub = () => {
		window.open(process.env.REACT_APP_EQHUB_URL);
	};
	const openAPIDocs = () => {
		window.open(process.env.REACT_APP_API_DOCS_URL);
	};

	return (
		<div id="Section1">
			<div className="section1-text-container">
				<div className="section1-main-text-wrapper">
					<div className="section1-main-text">The fastest and most scalable way</div>
					<div className="section1-main-text gradient">to build your web3 business</div>
				</div>
				<div
					className="section1-sub-text">{`Blockchain service development platform\nfrom building blockchain infra to DApp specialized solutions`}</div>
				<div className="section1-btn-wrapper">
					<div className="section1-btn" onClick={openEqhub} data-tag-index="main" data-tag-target="start">
						<div className="section1-btn-text" data-tag-index="main" data-tag-target="start">
							Get started
						</div>
					</div>
					<a className="section1-btn no-bg" href={"/#Section6"} data-tag-index="main" data-tag-target="API Docs">
						<div className="section1-btn-text" data-tag-index="main" data-tag-target="API Docs">
							Book demo
						</div>
					</a>
				</div>
			</div>
			<div className="section1-img-container">
				<div className="banner-container">
					<ul className="banner_list n1">
						{COOP_IMGS_1.map((coopImg) => (
							<li key={coopImg.id} className="banner">
								<img src={coopImg.src} alt={coopImg.alt} className={'banner-logo'}/>
							</li>
						))}
					</ul>
					<ul className="banner_list  n2">
						{COOP_IMGS_1.map((coopImg) => (
							<li key={coopImg.id} className="banner">
								<img src={coopImg.src} alt={coopImg.alt} className={'banner-logo'}/>
							</li>
						))}
					</ul>
				</div>
				<div className="banner-container">
					<ul className="banner_list reverse n1">
						{COOP_IMGS_2.map((coopImg) => (
							<li key={coopImg.id} className="banner">
								<img src={coopImg.src} alt={coopImg.alt} className={'banner-logo'}/>
							</li>
						))}
					</ul>
					<ul className="banner_list reverse n2">
						{COOP_IMGS_2.map((coopImg) => (
							<li key={coopImg.id} className="banner">
								<img src={coopImg.src} alt={coopImg.alt} className={'banner-logo'}/>
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
}

export default Section1;
