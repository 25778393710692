import React from "react";
import "./Section3WhisperCard.scss";
import linkButtonWhite from "../../../../common/asset/images/Link_Button_White.png"
function Section3WhisperCard({cardData}) {
	return (
		<div id="Section3WhisperCard">
			<div className="section3-whisper-card-image-container">
				<img className="section3-whisper-card-image" src={cardData.titleImg} alt=""/>
			</div>
			<div className="section3-whisper-card-wrapper">
				<div className="section3-whisper-card-text-wrapper">
					<div className="section3-whisper-card-text-main">{cardData.textMain}</div>
					<div className="section3-whisper-card-text-sub">{cardData.textSub}</div>
				</div>
					<img className='section3-whisper-card-link-btn' src={linkButtonWhite} alt={''} onClick={()=>window.open(cardData?.link)}/>
			</div>
		</div>
	);
}

export default Section3WhisperCard;
